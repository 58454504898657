import { lazy } from "react";

const Login = lazy(() => import("../container/login"));
const LoginDashboard = lazy(() => import("../container/LoginDashboard"));

const routes = [
  {
    path: "/",
    exact: true,
    name: "Login",
    component: Login,
    private: false,
  },
  {
    path: "/dashboard",
    exact: true,
    name: "LoginDashboard",
    component: LoginDashboard,
    private: true,
  },
];

export default routes;
