import React, { memo } from "react";

const Sidebar = memo(() => {
  return (
    <div className="sidebar">
      <>
        <div className="sidebarLogo"></div>
        <div className="sidebarContentContainer"></div>
      </>
    </div>
  );
});

export default Sidebar;
