import React, { memo, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import {
  useOutsideAlerter,
  removeLocalStorageItem,
  getLocalStorageItem,
} from "common-components-incruiter";
import collapseBar from "../../assets/admin Images/collapsebar.svg";
import SearchBar from "../CommonComponent/SearchBar";
import bell from "../../assets/admin Images/bell.svg";
import { ReactComponent as Logout } from "../../assets/images/logout.svg";

const Header = memo(({ setShowsidebar, showSidebar }) => {
  const [openProfile, setOpenProfile] = useState(false);
  const ref = useRef();
  const history = useHistory();

  useOutsideAlerter(ref, () => setOpenProfile(false));

  const handleOpenProfileDropdown = () => {
    setOpenProfile(!openProfile);
  };

  const handleLogout = () => {
    removeLocalStorageItem("token");
    removeLocalStorageItem("access_token_incfeed");
    removeLocalStorageItem("access_token_incserve");
    removeLocalStorageItem("permissions");
    removeLocalStorageItem("refreshToken");
    removeLocalStorageItem("userData");
    removeLocalStorageItem("companyData");
    history.push("/");
  };

  return (
    <>
      <button
        className="collapse-btn d-center"
        // onClick={() => {
        //   setShowsidebar(!showSidebar);
        // }}
      >
        <img src={collapseBar} alt="collapse-bar" className="img-fluid" />
      </button>
      <div className="header-content d-flex align-items-center">
        <SearchBar />
        <div className="notification">
          <img
            src={bell}
            alt="notification-bell"
            className="head-notification img-fluid"
          />
        </div>
        <div className="profile-name f-14 f-bol text-theme-blue">
          {/* Hi, {JSON.parse(getLocalStorageItem("userData")).name} */}
        </div>
        <div
          className="profile-pic d-center f-15"
          onClick={handleOpenProfileDropdown}
        >
          A
        </div>
        {openProfile && (
          <div className="user-profile-container position-relative">
            {openProfile && (
              <div
                className="profile-menu position-absolute rounded f-l f-bol"
                ref={ref}
              >
                <div
                  className="profile-menu-content d-flex align-items-center m-2 rounded"
                  onClick={handleLogout}
                >
                  <Logout className="img-fluid logout" />
                  Logout
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
});

export default Header;
