import React, { lazy, memo, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect  } from "react-router-dom";
import { Loader } from "common-components-incruiter";
import RoutesFile from "./RoutesFile";
import PrivateRoute from "./PrivateRoute";

import { getLocalStorageItem } from "common-components-incruiter";

const NotFound = lazy(() => import("../container/404NotFound.js"));

const Routes = memo(() => {
  return (
    <Router>
      <Suspense fallback={<Loader />}>
        <Switch>
          {RoutesFile.map((itm, key) =>
            itm.private ? (
              
                getLocalStorageItem("token") ?
                  <PrivateRoute
                  key={key}
                  exact={itm.exact}
                  path={itm.path}
                  name={itm.name}
                  component={itm.component}
                  />
                :
                <Redirect to="/" />

            ) : (
              <Route
                key={key}
                exact={itm.exact}
                path={itm.path}
                name={itm.name}
                component={itm.component}
              />
            )
          )}
          <Route component={NotFound} />
        </Switch>
      </Suspense>
    </Router>
  );
});

export default Routes;
