import React, { memo } from "react";
import { Route } from "react-router-dom";
import { getLocalStorageItem } from "common-components-incruiter";
import { Header, Sidebar } from "../component/Layout";

const PrivateRoute = memo(({ component: Component, ...rest }) => {
  return (
    <div className="main-container">
      <div className={`mainSidebar`}>
        <Sidebar />
      </div>
      <div className={`headerPageContainer`}>
        <div className="mainHeader input-shadow d-between">
          <Header />
        </div>
        <div className="pageContainer">
          <Route
            key={"mainRoute"}
            {...rest}
            render={(props) =>
              getLocalStorageItem("token") ? (
                <Component {...props} />
              ) : (
                <Component {...props} />
              )
            }
          />
        </div>
      </div>
    </div>
  );
});

export default PrivateRoute;
