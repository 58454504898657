import React, { memo } from "react";
import { render } from "react-dom";
import { ToastContainer, Slide } from "react-toastify";
import Routes from "./routes";
import "./libcss";

const CloseButton = ({ closeToast }) => (
  <i
    className="fa fa-times-circle align-self-center f-18 text-white"
    onClick={closeToast}
  />
);

const MainApp = memo(() => {
  return (
    <>
      <Routes />
      <ToastContainer
        autoClose={2000}
        draggable={false}
        transition={Slide}
        closeButton={CloseButton}
        hideProgressBar={true}
        toastClassName="toast-notification"
      />
    </>
  );
});

const rootElement = document.getElementById("root");

render(<MainApp />, rootElement);
