import React, { memo, useState } from "react";
import search from "../../assets/admin Images/search-blue.svg";
import close from "../../assets/admin Images/close.svg";

const SearchBar = memo(({ handleSearch, handleAfterClearSearch }) => {
  const [searchValue, setSearchValue] = useState("");

  const clearInputField = () => {
    setSearchValue("");
    if (handleAfterClearSearch) {
      handleAfterClearSearch();
    }
  };

  return (
    <div className="search-container rounded">
      <input
        type="text"
        name="search"
        placeholder="Search..."
        className="search-input f-10 f-bol"
        value={searchValue}
        onChange={(e) => {
          setSearchValue(e.target.value), handleSearch && handleSearch(e);
        }}
      />
      {searchValue.length === 0 ? (
        <img className="search-btn img-fluid" src={search} alt="search-bar" />
      ) : (
        <img
          src={close}
          alt="close-btn"
          className="search-btn"
          style={{width:"14px"}}
          onClick={clearInputField}
        />
      )}
    </div>
  );
});

export default SearchBar;
